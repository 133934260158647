// import animate on scroll
// import Aos from 'aos';
import Aos from 'aos';
import 'aos/dist/aos.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './components/pages/Homepage/Homepage';
import NotFound from './components/pages/NotFound/NotFound';

import 'swiper/css';

function App() {
  // animate on scroll initialization
  Aos.init({
    duration: 1800,
    offset: 0,
  });
  return (
    <BrowserRouter>
      {/* <Preloader /> */}
      <Routes>
        <Route>
          <Route path='/' element={<Homepage />} />
          {/* <Route path='/about' element={<About />} />
          <Route path='/program' element={<Program />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/blog-and-new' element={<Blog />} /> */}
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
