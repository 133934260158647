import About from '../../About/About';
import CTA from '../../CTA/CTA';
import Events from '../../Events/Events';
import Footer from '../../Footer/Footer';
import Gallery from '../../Gallery/Gallery';
import Hero from '../../Hero/Hero';
import Impact from '../../Impact/Impact';
import Learning from '../../Learning/Learning';
import Partners from '../../Partners/Partners';
import ScrollNav from '../../ScrollNav/ScrollNav';
import Blog from '../Blog/Blog';
import Curriculum from '../Curriculum/Curriculum';
import Program from '../Program/Program';

const Homepage = () => {
  return (
    <div className='max-full mx-auto '>
      <ScrollNav />
      <Hero />
      <Gallery />
      <Program />
      <About />
      <Impact />
      <Learning />
      <Curriculum />
      <Events />
      <CTA />
      <Partners />
      <Blog />
      <Footer />
    </div>
  );
};

export default Homepage;
