import { BiSolidBadge } from 'react-icons/bi';
import { FaFolderOpen, FaPanorama } from 'react-icons/fa6';
import { FiPaperclip } from 'react-icons/fi';
import { IoIosInformationCircle } from 'react-icons/io';
import { IoNewspaperOutline } from 'react-icons/io5';
import { MdGroups, MdLaptop } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Fade } from 'react-slideshow-image';

import { useState } from 'react';
import 'react-slideshow-image/dist/styles.css';
import { useScrollToView } from '../../hooks';
import MobileNav from '../MobileNav';

const linkData = [
  {
    id: 1,
    linkText: 'Showcase',
    Icon: FaPanorama,
    to: 'showcase',
  },
  {
    id: 2,
    linkText: 'About Us',
    Icon: IoIosInformationCircle,
    to: 'about-us',
  },
  {
    id: 3,
    linkText: 'Courses',
    Icon: BiSolidBadge,
    to: 'courses',
  },
  {
    id: 4,
    linkText: 'Programs',
    Icon: FaFolderOpen,
    to: 'program',
  },
  {
    id: 5,
    linkText: 'E-Learning',
    Icon: MdLaptop,
    to: 'e-learning',
  },
  {
    id: 6,
    linkText: 'Community',
    Icon: MdGroups,
    to: 'community',
  },
  {
    id: 7,
    linkText: 'Blog & News',
    Icon: IoNewspaperOutline,
    to: 'blog',
  },
  {
    id: 8,
    linkText: 'Register',
    Icon: FiPaperclip,
    to: '/',
  },
];

const fadeImages = [
  '/assets/hero.png',
  '/assets/hero2.png',
  '/assets/hero3.png',
  '/assets/hero4.png',
  '/assets/hero5.png',
  '/assets/hero6.png',
  '/assets/hero7.png',
  '/assets/hero8.png',
  '/assets/hero9.png',
  '/assets/hero10.png',
];

const fadeProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  pauseOnHover: false,
};

const NavComponent = () => {
  const scrollToView = useScrollToView();
  return (
    <nav className='absolute top-48 mx-auto hidden w-full max-w-6xl items-center justify-center md:inset-0 md:flex'>
      <ul className='relative flex h-full max-h-20 w-full flex-wrap justify-center gap-2 md:inset-0 md:items-center md:gap-4'>
        {linkData.map((link) => {
          const { id, linkText, Icon, to } = link;
          return (
            <li
              key={id}
              className='group mb-3 min-w-[130px] bg-[#161515] px-2 py-6 text-center text-white opacity-60 transition-all hover:scale-125 hover:bg-[#0784c3] group-hover:bg-[#0784c3] md:px-4 md:py-12'
            >
              <Link to={`/#${to}`} onClick={() => scrollToView(to)}>
                <Icon className='mx-auto h-12 w-12 text-[#0784c3] group-hover:text-white md:h-14 md:w-14' />

                <span className='capitalize text-white'>{linkText}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const Hero = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className='sticky -top-4 left-0 z-40 flex h-24 w-full justify-between bg-black lg:hidden'>
        <Link to='/'>
          <img
            src='/wificombat.svg'
            alt='homepage'
            className='h-24 w-24 px-2.5 py-2'
          />
        </Link>
        <HambugerIcon open={open} setOpen={setOpen} />
      </div>
      <Fade {...fadeProperties}>
        {fadeImages.map((image, index) => (
          <div key={index} className='each-fade'>
            <div className='image-container -z-40'>
              <img className='w-full lg:min-h-[60vh]' src={image} />
            </div>
            <nav className='absolute mx-auto -mt-24 flex w-full max-w-6xl items-center justify-center md:inset-0'>
              <NavComponent />
            </nav>
          </div>
        ))}
      </Fade>
      <div className='absolute left-0 top-0 z-50 block'>
        {open && <MobileNav setOpen={setOpen} handleOpen={handleOpen} />}
      </div>
    </>
  );
};

export default Hero;

const HambugerIcon = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <button
      id='toggleSidebarMobile'
      aria-expanded='true'
      aria-controls='sidebar'
      className='isolate z-50 mr-2 inline-block cursor-pointer rounded px-4 text-gray-300'
      onClick={() => {
        setOpen(!open);
        // console.log('clicked====', open);
      }}
    >
      <svg
        id='toggleSidebarMobileHamburger'
        className='h-6 w-6'
        fill='currentColor'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
          clip-rule='evenodd'
        ></path>
      </svg>
      <svg
        id='toggleSidebarMobileClose'
        className='hidden h-6 w-6'
        fill='currentColor'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
          clip-rule='evenodd'
        ></path>
      </svg>
    </button>
  );
};

// const MenuMobile = ()=>{
//   return(

//   )
// }
