const Learning = () => {
  return (
    <section
      className='flex flex-col overflow-x-hidden bg-white pb-32 pt-16'
      id='e-learning'
    >
      <h3 className='mb-16 ml-16 text-left text-3xl font-semibold'>
        E-Learning Platform
      </h3>

      <div className='flex min-h-[586px] flex-col gap-[72px] lg:flex-row'>
        <div
          data-aos='fade-down-right'
          className='max-w-xl flex-1'
        >
          {' '}
          <img
            src='https://res.cloudinary.com/depkwgbwl/image/upload/v1709812001/impact/learning_m7whow.png'
            alt=''
            className='min-h-full w-full object-cover'
          />
        </div>
        <div className='relative max-w-xl flex-1'>
          <div
            data-aos='fade-down-left'
            className='absolute -right-2 h-[720px] w-72 rounded-br-[20px] rounded-tl-[20px] bg-[#bc00dd] md:w-[400px] lg:right-[-90px] lg:h-[562px] lg:w-[555px]'
          ></div>
          <div
            data-aos='fade-down-left'
            data-aos-delay='200'
            data-aos-duration='3000'
            className='relative top-16 z-10 rounded-br-[20px] rounded-tl-[20px] bg-white p-8 shadow-lg'
          >
            <h3 className='mb-5 text-3xl font-semibold'>
              WIFICOMBAT E-Learning Platform
            </h3>
            <p className='mb-10'>
              Wificombat E-learning is a comprehensive online learning platform
              offering a Tech Career Pathway with foundation courses for Key
              Stage 1 & 2, as well as intermediary and advanced courses for
              Secondary and Post Secondary levels. The diverse curriculum covers
              Game Design & Development, Multimedia Design, Software
              Development, and Emerging Technologies. The platform creates a
              collaborative space through its Online Tech Community, providing
              dedicated areas for pre-teens and teens to engage in discussions,
              forums, and collaborative projects.
              <br />
              <br />
              Tech Career Guidance features counseling resources, virtual career
              fairs, and tools to explore different tech roles. With a
              user-friendly interface, collaboration tools, regular updates, and
              ramification elements, Wificombat Elearn ensures an accessible,
              secure, and engaging learning environment for tech enthusiasts of
              all ages.
            </p>

            <button className='rounded-lg bg-black px-6 py-3 capitalize text-white transition-all hover:translate-y-1'>
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Learning;
