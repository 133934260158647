import { Link } from 'react-router-dom';
import { useActiveSection, useScrollToView } from '../hooks';

const MobileNav = ({
  setOpen,
  handleOpen,
}: {
  setOpen: any;
  handleOpen: any;
}) => {
  const scrollToView = useScrollToView();
  // const isVisible = useNavbarVisibility();
  // const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const activeSection = useActiveSection([
    'showcase',
    'about-us',
    'courses',
    'program',
    'e-learning',
    'community',
    'blog',
  ]);

  // const toggleOverlay = () => {
  //   setIsOverlayVisible(!isOverlayVisible);
  // };
  return (
    <aside className='z-50 flex min-h-screen items-center justify-center'>
      <div x-data='{ open: true }'>
        {/* Sidebar Overlay */}
        <div
          onClick={() => setOpen(false)}
          className='fixed inset-0 z-50 overflow-hidden'
        >
          <div className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-all duration-300 ease-in-out' />
          {/* Sidebar Content */}
          <section className='absolute inset-y-0 right-0 flex max-w-full pl-10'>
            <div
              x-show='open'
              x-transition:enter='transition-transform ease-out duration-300'
              x-transition:enter-start='transform translate-x-full'
              x-transition:enter-end='transform translate-x-0'
              x-transition:leave='transition-transform ease-in duration-300'
              x-transition:leave-start='transform translate-x-0'
              x-transition:leave-end='transform translate-x-full'
              className='w-screen max-w-md'
            >
              <div className='flex h-full flex-col bg-white py-6 shadow-xl'>
                {/* Sidebar Header */}
                <div className='ml-auto flex justify-end px-4'>
                  <button
                    onClick={handleOpen}
                    className='text-gray-500 hover:text-gray-700'
                  >
                    <span className='sr-only'>Close</span>
                    <svg
                      className='h-6 w-6'
                      x-description='Heroicon name: x'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>

                <div className='mt-4 h-full overflow-auto px-4'>
                  <div className='flex flex-col space-y-4'>
                    <Link
                      to='/#showcase'
                      onClick={() => scrollToView('showcase')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'showcase' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'showcase' ? 'text-blue-500' : ''
                        }`}
                      >
                        Showcase
                      </span>
                    </Link>
                    <Link
                      to='/#about-us'
                      onClick={() => scrollToView('about-us')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'about-us' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'about-us' ? 'text-blue-500' : ''
                        }`}
                      >
                        About Us
                      </span>
                    </Link>
                    <Link
                      to='/#courses'
                      onClick={() => scrollToView('courses')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'courses' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'courses' ? 'text-blue-500' : ''
                        }`}
                      >
                        Courses
                      </span>
                    </Link>
                    <Link
                      to='/#program'
                      onClick={() => scrollToView('program')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'program' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'program' ? 'text-blue-500' : ''
                        }`}
                      >
                        Programs
                      </span>
                    </Link>
                    <Link
                      to='/#e-learning'
                      onClick={() => scrollToView('e-learning')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'e-learning' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'e-learning' ? 'text-blue-500' : ''
                        }`}
                      >
                        E-Learning
                      </span>
                    </Link>
                    <Link
                      to='/#community'
                      onClick={() => scrollToView('community')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'community' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'community' ? 'text-blue-500' : ''
                        }`}
                      >
                        Community
                      </span>
                    </Link>
                    <Link
                      to='/#blog'
                      onClick={() => scrollToView('blog')}
                      className={`flex items-center justify-between px-4 py-2 text-xl font-medium text-gray-700 ${
                        activeSection === 'blog' ? 'text-blue-500' : ''
                      }`}
                    >
                      <span
                        className={`${
                          activeSection === 'blog' ? 'text-blue-500' : ''
                        }`}
                      >
                        News
                      </span>
                    </Link>
                    <Link
                      to='/'
                      className='flex items-center justify-between px-4  py-2 text-xl font-medium text-gray-700'
                    >
                      <span>Register</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Your main content goes here */}
      </div>
    </aside>
  );
};

export default MobileNav;
