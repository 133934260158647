const eventsData = [
  {
    title: 'Event 1',
    image:
    'https://res.cloudinary.com/dl5tm4osj/image/upload/v1721060608/nkc8rfg6tynmzkdkrewh.jpg'
  },
  {
    title: 'Event 2',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704876038/events/event-1_o67nnn.png',
  },
  {
    title: 'Event 3',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704876037/events/event-2_gtumfj.png',
  },
  {
    title: 'Event 4',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704876036/events/event-3_igkxs0.png',
  },
  // {
  //   title: 'Event 4',
  //   image:
  //     'https://images.unsplash.com/photo-1626176239067-6e1b9a5a3c4d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGJhY2tncm91bmQlMjB3b21lbnRzJTIwZm9yJTIwYmFja2dyb3VuZCUyMGJhY2tncm91bmQlMjB3b21lbnRzJTI',
  // },
  // {
  //   title: 'Event 5',
  //   image:
  //     'https://images.unsplash.com/photo-1626176239067-6e1b9a5a3c4d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGJhY2tncm91bmQlMjB3b21lbnRzJTIwZm9yJTIwYmFja2dyb3VuZCUyMGJhY2tncm91bmQlMjB3b21lbnRzJTI',
  // },
];

const Events = () => {
  return (
    <section className='relative overflow-hidden bg-white pt-32' id='blog'>
      <div className='absolute inset-0 top-3 flex h-6 items-center justify-center md:top-[18px]'>
        <svg
          width='122'
          height='60'
          viewBox='0 0 122 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            id='Polygon 1'
            d='M61 59.9221L0.37822 -0.0778809L121.622 -0.0778809L61 59.9221Z'
            fill='#BC00DD'
          />
        </svg>
      </div>
      <div
        style={{ backgroundImage: "url('/assets/time.png')" }}
        className='absolute -right-10 -top-28 h-1/3 w-40 bg-no-repeat object-contain max-sm:scale-50 md:right-0 lg:right-0 lg:top-0 lg:h-full lg:w-40'
      />

      <div className='absolute inset-0 -left-16 top-20 mx-auto h-6 w-6 md:top-40'>
        <svg
          width='100'
          height='100'
          viewBox='0 0 100 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='50' cy='50' r='50' fill='#FFB600' />
          <path
            d='M49.7817 24.7246C46.4987 24.7246 43.2478 25.3713 40.2147 26.6276C37.1815 27.884 34.4255 29.7255 32.1041 32.0469C27.4157 36.7353 24.7817 43.0942 24.7817 49.7246C24.7817 56.355 27.4157 62.7139 32.1041 67.4023C34.4255 69.7237 37.1815 71.5652 40.2147 72.8216C43.2478 74.078 46.4987 74.7246 49.7817 74.7246C56.4122 74.7246 62.771 72.0907 67.4594 67.4023C72.1478 62.7139 74.7817 56.355 74.7817 49.7246C74.7817 46.4416 74.1351 43.1907 72.8787 40.1575C71.6224 37.1244 69.7809 34.3684 67.4594 32.0469C65.1379 29.7255 62.382 27.884 59.3488 26.6276C56.3157 25.3713 53.0648 24.7246 49.7817 24.7246ZM60.2817 60.2246L47.2817 52.2246V37.2246H51.0317V50.2246L62.2817 56.9746L60.2817 60.2246Z'
            fill='white'
          />
        </svg>
      </div>

      <h2 className='mb-4 mt-20 text-center text-3xl font-semibold uppercase md:mt-40 lg:text-7xl lg:font-normal'>
        Events
      </h2>

      <div className=''>
        <div className='mx-auto max-w-7xl gap-5 pb-12'>
          <h3 className='my-10 pl-4 text-2xl font-medium lg:text-4xl lg:font-semibold'>
            Upcoming Events
          </h3>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center justify-center gap-3'>
            {eventsData.map((event) => (
              <div className='w-full object-cover h-[380px] flex scale-90 transition-transform duration-500 hover:scale-95'>
                <img src={event.image} alt={event.title} className="w-full object-cover aspect-auto"/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;
