import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const programData = [
  {
    id: 1,
    title: 'Summer Bootcamp',
    description:
      'Our summer boot camps are very unique, for we use the platform to introduce and train Kids and Teens on emerging technology. Every year we partner with schools and smaller organizations to train over 500 kids and teens on AR/VR workshops, 3D Modelling, Drone Technology, 3D Printing, Wearable Technology; Robotics and IOT.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705432538/programs/Summer_Bootcamp_oxp22p.png',
    link: '/',
  },
  {
    id: 2,
    title: 'Weekend Online Classes',
    description:
      'Our unique tech boot camps blend virtual and physical training, supported by an interactive e-learning platform. Participants explore subjects like AR/VR, 3D Modelling, Drone Technology, 3D Printing, Wearable Technology, Robotics, and IoT. This approach encourages hands-on learning and innovation.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705433290/programs/Weekend_Online_Classes_ka15zi.png',
    link: '/',
  },
  {
    id: 3,
    title: 'Teen Techpreneurship',
    description:
      'Our objective is to create an interactive learning platform to train young aspiring tech entrepreneurs in Entrepreneurship, Business Management and Technical Skills, that will ultimately translate into improved efficiency and productivity. And to kindle an entrepreneurial spirit whereby teens can think outside the box and nurture unconventional tale',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705433233/programs/Teen_Techpreneurship_tjn0th.png',
    link: 'https://elearn.wificombatacademy.com/',
  },
  {
    id: 4,
    title: 'BSS (Beyond Secondary School)',
    description:
      'To bridge this gap, our organization Launching Tech Career Pathway offering three to  six months  tech  program in the following career programs; Software Developer, Game Developer, AI & Data Science and Multimedia  Design. We offer mentorship programs were the students will have the opportunity to',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705433167/programs/BSS_Beyond_Secondary_School_b32awk.png',
  },
  {
    id: 5,
    title: 'Codeweek Nigeria',
    description:
      'Code Week Nigeria is an empowerment program engaging teachers and children, from both public and private schools across Nigeria, in building various tech skills to solve local problems within their communities.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705433100/programs/Codeweek_Nigeria_kytxfi.png',
  },
  {
    id: 6,
    title: 'Oracle Academy Students Hackathon',
    description:
      'Students from both public and private primary & secondary schools compete in the Coding Competition using tech programs of the Oracle Academy- Java fundamentals with Alice & Greenfoot and Oracle SQL.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705432888/programs/Oracle_Academy_Students_Hackathon_titaj1.png',
  },
  //Todo: Add more programs and edit number 7
  {
    id: 7,
    title: 'Oracle Teachers Hackathon',
    description:
      'Teachers from various private and public schools competed in the various programming languages such as Java fundamentals with Alice & Greenfoot and OracleSQL. The aim of the competition is to encourage our Teachers to be at the fore front of Technology, helping them learn and update their skills to.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705432888/programs/Oracle_Academy_Students_Hackathon_titaj1.png',
  },
  {
    id: 8,
    title: 'Tech Hackathons',
    description:
      'We prepare our students to participate in international competitions such as Technovation, Conrad Challenge Competition.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1708982826/programs/1c808f43-6e81-44f9-8f77-fd4f2223a110.png',
  },
  {
    id: 9,
    title: 'Career Workshop',
    description:
      "WifiCombat Academy's Teen Career Workshop is a dynamic and engaging event designed to empower and guide teenagers towards making informed choices about their future careers. The workshop offers a unique blend of interactive sessions, expert-led discussions, and hands-on activities to help participants explore various career paths, develop esse .",
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1708983827/programs/3c4d2ca8-04aa-480c-9f07-c7b4eec4e1b1.png',
  },
  {
    id: 10,
    title: 'Educators Workshop',
    description:
      "WifiCombat Training presents the 'Educators Workshop,' a specialized training program aimed at equipping teachers with essential digital skills to enhance their teaching methodologies and adapt to the evolving educational landscape. This workshop provides educators with hands-on training in incorporating technology into the classroom, fost.",
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1708984584/programs/e2624e28-6088-4b40-9c37-7ac783e728d9.png',
  },
];

const Program = () => {
  return (
    <section
      className='relative max-h-fit w-full overflow-hidden bg-white pt-32'
      id='program'
    >
      <div className='absolute inset-0 top-3 flex h-6 items-center justify-center md:top-[18px]'>
        <svg
          width='122'
          height='60'
          viewBox='0 0 122 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            id='Polygon 1'
            d='M61 59.9221L0.37822 -0.0778809L121.622 -0.0778809L61 59.9221Z'
            fill='#BC00DD'
          />
        </svg>
      </div>

      <div
        style={{ backgroundImage: "url('/assets/folder.png')" }}
        className='absolute -right-10 -top-28 h-1/3 w-40 bg-no-repeat object-contain max-sm:scale-50 md:right-0 lg:right-0 lg:top-0 lg:h-full lg:w-40'
      />

      <div className='absolute inset-0 -left-16 top-20 mx-auto h-6 w-6 md:top-40'>
        <svg
          width='100'
          height='100'
          viewBox='0 0 100 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='50' cy='50' r='50' fill='#FFB600' />
          <path
            d='M67.5 57.5C67.5 67.175 59.675 75 50 75C40.325 75 32.5 67.175 32.5 57.5C32.5 50.525 36.6 44.5 42.5 41.675V32.5C42.5 31.125 43.625 30 45 30H46.25L43.75 25H56.25L53.75 30H55C56.375 30 57.5 31.125 57.5 32.5V41.675C63.4 44.5 67.5 50.525 67.5 57.5ZM47.5 35V45.25C44.6766 45.8263 42.139 47.3605 40.3167 49.5927C38.4945 51.825 37.4994 54.6184 37.5 57.5L37.7 59.775L42.5 54.825L52.675 65L62.325 55.35C61.8885 52.8756 60.7183 50.5895 58.9663 48.7885C57.2143 46.9874 54.9614 45.7546 52.5 45.25V35H47.5ZM52.675 50C54.05 50 55.175 51.125 55.175 52.5C55.175 53.875 54.05 55 52.675 55C51.25 55 50.175 53.875 50.175 52.5C50.175 51.125 51.25 50 52.675 50Z'
            fill='white'
          />
        </svg>
      </div>
      <h2 className='mb-4 mt-20 text-center text-3xl font-semibold uppercase md:mt-40 lg:text-7xl lg:font-normal'>
        Programs
      </h2>

      <div
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/depkwgbwl/image/upload/v1705429448/programs-bg_uei9wa.png')",
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className='relative inset-0 w-full px-10'
      >
        <div className='absolute inset-0 bg-[#573b5b] bg-opacity-70' />
        <Swiper
          slidesPerGroup={1}
          slidesPerView={'auto'}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          loop={true}
          modules={[Navigation, Autoplay, Pagination, A11y]}
          className='relative mx-auto flex max-w-7xl py-32'
        >
          <div className='absolute inset-0 bg-purple-950 bg-opacity-50' />
          {programData.map((program) => (
            <SwiperSlide key={program.id}>
              <article className='relative flex h-[700px] flex-1 flex-col bg-white'>
                <img
                  src={program.image}
                  alt={program.title + ' image'}
                  className='h-72 w-full'
                  loading='lazy'
                />
                <div className='p-4 pb-5'>
                  <h2 className='text-2xl font-semibold'>{program.title}</h2>
                  <p className='mb-5 mt-2'>{program.description + '...'}</p>
                </div>
                <Link
                  to={program.link || '/'}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='mb-4 ml-4 mt-auto w-fit rounded-lg bg-black px-6 py-3 capitalize text-white transition-all hover:translate-y-1'>
                    read more
                  </button>
                </Link>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Program;
