const About = () => {
  return (
    <section
      className='relative w-full overflow-hidden bg-white pt-32'
      id='about-us'
    >
      <div className='absolute inset-0 top-3 flex h-6 items-center justify-center md:top-[18px]'>
        <svg
          width='122'
          height='60'
          viewBox='0 0 122 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            id='Polygon 1'
            d='M61 59.9221L0.37822 -0.0778809L121.622 -0.0778809L61 59.9221Z'
            fill='#BC00DD'
          />
        </svg>
      </div>

      <div
        style={{ backgroundImage: "url('/assets/folder.png')" }}
        className='max absolute -right-10 -top-48 h-1/3 w-40 bg-no-repeat object-contain max-sm:scale-50 md:right-0 lg:right-0 lg:top-0 lg:h-full lg:w-40'
      />

      <div className='absolute inset-0 -left-16 top-20 mx-auto h-6 w-6 md:top-40'>
        <svg
          width='100'
          height='100'
          viewBox='0 0 100 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='50' cy='50' r='50' fill='#FFB600' />
          <path
            d='M50 30C52.6522 30 55.1957 31.0536 57.0711 32.9289C58.9464 34.8043 60 37.3478 60 40C60 42.6522 58.9464 45.1957 57.0711 47.0711C55.1957 48.9464 52.6522 50 50 50C47.3478 50 44.8043 48.9464 42.9289 47.0711C41.0536 45.1957 40 42.6522 40 40C40 37.3478 41.0536 34.8043 42.9289 32.9289C44.8043 31.0536 47.3478 30 50 30ZM50 55C61.05 55 70 59.475 70 65V70H30V65C30 59.475 38.95 55 50 55Z'
            fill='white'
          />
        </svg>
      </div>

      <h2 className='mb-4 mt-20 text-center text-3xl font-semibold uppercase md:mt-40 lg:text-7xl lg:font-normal'>
        About
      </h2>

      <div className='w-full bg-[#e3d6f5]'>
        <div className='px mx-auto flex flex-col gap-5 px-4 py-10 md:px-16'>
          <div className='flex flex-col gap-28 lg:flex-row'>
            <div
              data-aos='fade-right'
              className='lg:-[618px] rounded-[20px] lg:h-[380px]'
            >
              <img
                src='https://res.cloudinary.com/depkwgbwl/image/upload/v1705433955/about/about-1_qce2lq.png'
                alt=''
                className='max-h-full w-full rounded-[20px] object-cover'
              />
            </div>
            <div
              data-aos='fade-left'
              className='mx-auto sm:px-4 lg:mx-0 lg:max-w-lg'
            >
              <h3 className='mb-10 text-4xl font-semibold'>
                Why Wificombat Academy
              </h3>
              <p className='mb-12 leading-6'>
                The Wifi Combat Academy is a <b>Digital Incubation Hub</b>{' '}
                offering a variety of{' '}
                <strong>Educational Technology Solutions</strong> to{' '}
                <strong>Institutions, schools,</strong> and the{' '}
                <strong>Government</strong> to help bridge the gap of{' '}
                <strong>preparing our children</strong> for the{' '}
                <strong>21st century workforce.</strong>
                <span className='mt-4 block'>
                  We have recognized the importance of technology at an early
                  age for children and teens, and we know the importance of
                  developing our teens to become Global problem solvers.
                </span>
              </p>
              <button className='rounded-lg bg-black px-6 py-3 capitalize text-white transition-all hover:translate-y-1'>
                Learn More
              </button>
            </div>
          </div>
          <div className='mb-10 mt-16 text-center'>
            <h3 className='text-3xl font-semibold'>
              Hear what some of our users has to say{' '}
            </h3>
            <p>
              Discover the Transformative Experiences Shared by Our Amazing
              Users
            </p>
          </div>
          <div className='flex flex-col gap-[76px] lg:flex-row'>
            <div
              data-aos='fade-down-right'
              className='rounded-[20px] lg:h-[380px] lg:w-[618px]'
            >
              <img
                src='https://res.cloudinary.com/depkwgbwl/image/upload/v1709811651/about/Wfifcombat_Parent_pnbvje.png'
                alt=''
                className='max-h-full w-full rounded-[20px] object-cover'
              />

              {/* <VideoPlayer width={'100%'} height={'100%'} /> */}
              <p className='mt-5 text-center text-3xl font-semibold'>
                Wificombat Parent
              </p>
            </div>
            <div
              data-aos='fade-down-left'
              className='rounded-[20px] lg:h-[380px] lg:w-[618px]'
            >
              <YouTubeEmbed videoId='6p6Ajve4aao' />

              <p className='mt-5 text-center text-3xl font-semibold'>
                Wificombat Alumni
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

const YouTubeEmbed = ({ videoId }: { videoId: string }) => {
  const src = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className='youtube-video-container'>
      <iframe
        className='h-56 w-full rounded-[20px] lg:h-[315px] lg:w-[560px]'
        // width='560'
        // height='315'
        src={src}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='YouTube video player'
      ></iframe>
    </div>
  );
};
