import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
const programData = [
  {
    id: 1,
    title:
      'WIFICOMBAT STUDENTS EXHIBIT TECH PROJECTS TO GERMAN DELEGATES FROM DLD',
    description:
      'Wificombat, our organization, had the honour of showcasing the impressive tech skills of our #wifiteens, thanks to @asubiojomoyo, the GM of @vibraniumvalley, during the Gtlobaltechafrica event held  at Vibranium Valley.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705435986/blog/blog-1_rr9gek.png',
  },
  {
    id: 2,
    title: 'WIFICOMBAT STUDENTS WIN BIG AT THE GTA CONFERENCE',
    description:
      'Students were awarded N200,00 at the GTA conference competition, schools like Julliard Academy, Halifdield School, Childville School, and students of the Wificombat Academy participated in the competition',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705435991/blog/blog-2_t5x612.png',
  },
  {
    id: 3,
    title: 'WIFICOMBAT STUDENTS GOT TO CONRAD FINALS',
    description:
      'Halifield schools emerged finalists at the Connrad Challenge competition They built a  power management device and an app designed to address power challenges in homes due to high tariffs.',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705435988/blog/blog-3_ybkfca.png',
  },
];

const Blog = () => {
  return (
    <section className='relative overflow-hidden bg-white pt-32' id='blog'>
      <div className='absolute inset-0 top-3 flex h-6 items-center justify-center md:top-[18px]'>
        <svg
          width='122'
          height='60'
          viewBox='0 0 122 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            id='Polygon 1'
            d='M61 59.9221L0.37822 -0.0778809L121.622 -0.0778809L61 59.9221Z'
            fill='#BC00DD'
          />
        </svg>
      </div>
      <div
        style={{ backgroundImage: "url('/assets/newspaper.png')" }}
        className='absolute -right-10 -top-28 h-1/3 w-40 bg-no-repeat object-contain max-sm:scale-50 md:right-0 lg:right-0 lg:top-0 lg:h-full lg:w-40'
      />

      <div className='absolute inset-0 -left-16 top-20 mx-auto h-6 w-6 md:top-40'>
        <svg
          width='100'
          height='100'
          viewBox='0 0 100 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='50' cy='50' r='50' fill='#FFB600' />
          <path
            d='M70 32.5V67.5H30V32.5H70ZM70 27.5H30C27.225 27.5 25 29.725 25 32.5V67.5C25 70.275 27.225 72.5 30 72.5H70C72.775 72.5 75 70.275 75 67.5V32.5C75 29.725 72.775 27.5 70 27.5ZM65 57.5H35V62.5H65V57.5ZM45 37.5H35V52.5H45V37.5ZM50 42.5H65V37.5H50V42.5ZM65 47.5H50V52.5H65V47.5Z'
            fill='white'
          />
        </svg>
      </div>

      <h2 className='mb-4 mt-20 text-center text-3xl font-semibold uppercase md:mt-40 lg:text-7xl lg:font-normal'>
        Blog & News
      </h2>

      <div
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/depkwgbwl/image/upload/v1705761455/blog_bg_pfojkk.png')",
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className='relative inset-0'
      >
        <div className='absolute inset-0' />
        <Swiper
          slidesPerGroup={1}
          slidesPerView={'auto'}
          navigation
          loop={false}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
              loop: false,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 120,
              loop: false,
            },
            1000: {
              slidesPerView: 3,
              loop: false,
              spaceBetween: 50,
            },
          }}
          modules={[Navigation, Autoplay, Pagination, A11y]}
          className='mx-auto flex max-w-7xl bg-[#573b5b] bg-opacity-70 px-5 py-32'
        >
          {programData.map((program) => (
            <SwiperSlide key={program.id}>
              <article key={program.id} className='h-[690px] flex-1 bg-white'>
                <img
                  src={program.image}
                  alt=''
                  className='h-72 w-full'
                  loading='lazy'
                />
                <div className='p-4 pb-5'>
                  <h2 className='text-2xl font-semibold'>{program.title}</h2>
                  <p className='mb-5 mt-2'>{program.description + '...'}</p>
                  <button className='rounded-lg bg-black px-6 py-3 capitalize text-white transition-all hover:translate-y-1'>
                    read more
                  </button>
                </div>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Blog;
