const CTA = () => {
  return (
    <div className='bg-image mb-2 flex h-[490px] flex-col items-center justify-center px-3 text-center text-white'>
      <h2 className='text-3xl font-semibold lg:text-4xl'>
        Join Wificombat Academy community
      </h2>
      <p className='mx-auto mb-12 max-w-2xl text-lg font-medium leading-8 lg:text-xl'>
        Connecting Educators, Institutions, and Government for 21st Century
        Learning Solutions
      </p>
      <button className='rounded-lg bg-black px-6 py-3 capitalize text-white transition-all hover:translate-y-1'>
        join now
      </button>
    </div>
  );
};

export default CTA;
