import { Link } from 'react-router-dom';
import {
  useActiveSection,
  useNavbarVisibility,
  useScrollToView,
} from '../../hooks';

//     {/* nav / initially hidden / show on large screens  */}
//     <div className='hidden lg:flex'>
//     <Nav />
//   </div>
// </div>

// {/* mobile nav / initially is showing / hide on large screens */}
// <div
//   className={`${
//     navMobile ? 'max-h-52' : 'max-h-0'
//   } absolute left-0 right-0 top-24 w-full overflow-hidden rounded bg-accent-tertiary font-bold transition-all lg:hidden`}
// >
//   <NavMobile />
// </div>

const ScrollNav = () => {
  const scrollToView = useScrollToView();
  const isVisible = useNavbarVisibility();
  const activeSection = useActiveSection([
    'showcase',
    'about-us',
    'courses',
    'program',
    'e-learning',
    'community',
    'blog',
  ]);

  return (
    <nav
      className={`max-w-screen fixed left-0 top-0 z-20 h-24 w-full overflow-hidden border-b bg-secondary-900 transition-all duration-300 ease-in-out sm:w-full md:block ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <ul className='mx-auto flex max-w-6xl items-center justify-center gap-4'>
        <Link
          to='/#showcase'
          onClick={() => scrollToView('showcase')}
          className={`px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3] ${
            activeSection === 'showcase' ? 'border-t-2 border-blue-500' : ''
          }`}
        >
          showcase
        </Link>
        <Link
          to='/#about-us'
          onClick={() => scrollToView('about-us')}
          className={`px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3] ${
            activeSection === 'about-us' ? 'border-t-2 border-blue-500' : ''
          }`}
        >
          about us
        </Link>
        <Link
          to='/#courses'
          onClick={() => scrollToView('courses')}
          className={`px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3] ${
            activeSection === 'courses' ? 'border-t-2 border-blue-500' : ''
          }`}
        >
          Courses
        </Link>
        <Link
          to='/#program'
          onClick={() => scrollToView('program')}
          className={`px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3] ${
            activeSection === 'program' ? 'border-t-2 border-blue-500' : ''
          }`}
        >
          Programs
        </Link>

        <Link to='/'>
          <img
            src='/wificombat.svg'
            alt='homepage'
            className='h-24 w-24 px-2.5 py-2'
          />
        </Link>
        <Link
          to='/#e-learning'
          onClick={() => scrollToView('e-learning')}
          className={`px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3] ${
            activeSection === 'e-learning' ? 'border-t-2 border-blue-500' : ''
          }`}
        >
          E- Learning
        </Link>
        <Link
          to='/#community'
          onClick={() => scrollToView('community')}
          className='px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3]'
        >
          Community
        </Link>
        <Link
          to='/#blog'
          onClick={() => scrollToView('blog')}
          className='px-2.5 py-2 text-lg capitalize text-white hover:text-[#0784C3]'
        >
          News
        </Link>
        <Link
          to='/'
          className='px-2.5 py-2 text-lg capitalize text-[#0784C3] hover:text-[#0784C3]'
        >
          Register
        </Link>
      </ul>
    </nav>
  );
};

export default ScrollNav;
