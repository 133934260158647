import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const partnersData = [
  {
    name: 'Google',
    url: 'https://ploi.io',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705749897/partner/Google_svg_i50iem.svg',
  },
  {
    name: 'MTN',
    url: 'https://laravel-news.com/',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1709810620/partner/MTN_Nigeria_tca8hd.png',
  },
  {
    name: 'Guaranty Trust Bank',
    url: 'https://laravelshift.com/',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705749972/partner/GUARANTY_Trust_Bank_svg_xmv5pl.svg',
  },
  {
    name: 'Data Science Nigeria',
    url: 'https://laravelshift.com/',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1705749971/partner/DataScienceNigeria_-_png_neerwh.png',
  },
  {
    name: 'Oracle Academy',
    url: 'https://laravelshift.com/',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1707435573/partner/466d848a-ac29-476f-b12f-92a3f38ca6c0.png',
  },
];

const Partners = () => {
  return (
    <section className='relative bg-white pt-6'>
      <div className='mx-auto flex max-w-7xl flex-col flex-wrap justify-around gap-8 px-4 py-12'>
        <h3 className='text-4xl font-semibold'>Partners</h3>

        <Swiper
          slidesPerGroup={1}
          slidesPerView={'auto'}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
          }}
          loop={true}
          modules={[Navigation, Autoplay, Pagination, A11y]}
          className='mx-auto flex w-full items-center justify-center lg:max-w-7xl'
        >
          {partnersData.map((partner) => (
            <SwiperSlide key={partner.name}>
              <a
                className='flex w-32 items-center justify-center px-8 py-5 text-gray-400 transition-all duration-300 hover:scale-110 hover:text-gray-700 lg:w-[420px]'
                // href={'#'}
                // target='_blank'
                title={partner.name}
              >
                <img
                  className='mx-auto h-32 w-32 fill-current object-contain grayscale hover:grayscale-0 lg:h-36 lg:w-36'
                  src={partner.image}
                  alt={partner.name}
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
