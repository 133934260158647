import { useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';

const galleryData = [
  {
    id: 1,
    title: 'Educatores 2023',
    image: 'v1707431826/showcase/Educatores_2023_lmjtnh',
  },
  {
    id: 2,
    title: 'Teentech 2023  1',
    image: 'v1707433118/showcase/Teentech_2023_1_wcsics',
  },
  {
    id: 3,
    title: 'Teentech 2023',
    image: 'v1707433191/showcase/Teentech_2023_ydbcmd',
  },
  {
    id: 4,
    title: 'Microsoft excursion 2023 (2)',
    image: 'v1707433266/showcase/Microsoft_excursion_2023_2_fxmwzi',
  },
  {
    id: 5,
    title: 'Microsoft excursion 2023',
    image: 'v1707433344/showcase/Microsoft_excursion_2023_ccfloa',
  },
  {
    id: 6,
    title: 'activities 2023 2',
    image: 'v1707433492/showcase/activities_2023_2_qx4yhv',
  },
  {
    id: 7,
    title: 'summer bootcamp 2025',
    image: 'v1707433663/showcase/summer_bootcamp_2025_vfctm4',
  },
  {
    id: 8,
    title: 'actvities 2023',
    image: 'v1707433769/showcase/actvities_2023_v8anct',
  },
  {
    id: 9,
    title: 'Team',
    image: 'v1707433841/showcase/Team_d8yef0',
  },
  {
    id: 10,
    title: 'TEENTECH 2019',
    image: 'v1707434006/showcase/TEENTECH_2019_dw9k9l',
  },
  {
    id: 11,
    title: 'MTN 2019',
    image: 'v1707434154/showcase/MTN_2019_ek38sz',
  },
  {
    id: 12,
    title: 'Oracle Teachers Hackthon 2019',
    image: 'v1707434192/showcase/Oracle_Teachers_Hackthon_2019_eoaee1',
  },
  {
    id: 13,
    title: 'career workshop 2018',
    image: 'v1707434269/showcase/career_workshop_2018_f829b6',
  },
  {
    id: 14,
    title: 'Oracle Hackathon 2018',
    image: 'v1707434324/showcase/Oracle_Hackathon_2018_as1byj',
  },
  {
    id: 15,
    title: 'codeweek 2019',
    image: 'v1707434415/showcase/codeweek_2019_wbylst',
  },
  {
    id: 16,
    title: 'FR Excursion 2019',
    image: 'v1707434532/showcase/FR_Excursion_2019_djt7zw',
  },
  {
    id: 17,
    title: 'summerbootcamp 2018',
    image: 'v1707434600/showcase/summerbootcamp_2018_f0nrnl',
  },
  {
    id: 18,
    title: 'RnT summer 2017',
    image: 'v1707434659/showcase/RnT_summer_2017_zq0tex',
  },
  {
    id: 19,
    title: 'Oracle Hackathon 2017',
    image: 'v1707434779/showcase/Oracle_Hackathon_2017_jq8fdt',
  },
  {
    id: 20,
    title: 'oracle codeweek 2016',
    image: 'v1707434867/showcase/oracle_codeweek_2016_hajkjo',
  },
];

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleModal = (image: any) => {
    setIsOpen(!isOpen);
    setSelectedImage(image);
  };

  return (
    <div
      id='showcase'
      className='grid grid-cols-2 gap-px bg-[#131314] py-2 md:grid-cols-4 lg:min-h-[720px] lg:grid-cols-5'
    >
      {galleryData.map((item) => (
        <article
          onClick={() =>
            toggleModal(
              item.image
                ? `https://res.cloudinary.com/depkwgbwl/image/upload/${item.image}.webp`
                : `/assets/gallery/g${item.id}.png`
            )
          }
          className='relative'
          key={item.id}
        >
          <div className='absolute inset-0 flex items-center justify-center bg-[#43befc] bg-opacity-70 opacity-0 transition-opacity duration-500 hover:opacity-100'>
            <button className='rounded-full px-6 py-3 text-lg text-white focus:outline-none'>
              {/* View Fullscreen */}
              <FaCirclePlus className='h-16 w-6 text-[#131314]' />
            </button>
          </div>

          {isOpen && (
            <dialog className='modal' onClick={toggleModal}>
              <div
                className='modal-content'
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className='close-button rounded px-4 py-2 text-xl'
                  onClick={toggleModal}
                >
                  X
                </button>
                {selectedImage && (
                  <img className='w-full' src={selectedImage} alt='' />
                )}
              </div>
            </dialog>
          )}
          <img
            className='h-44 w-full max-w-full object-cover'
            src={
              item.image
                ? `/assets/gallery/g${item.id}.png`
                : `/assets/gallery/g${item.id}.png`
            }
            alt={item.title}
          />
        </article>
      ))}
    </div>
  );
};

export default Gallery;
