import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const programData = [
  {
    id: 1,
    title: 'Gaming',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704888567/curriculum/gaming_m16uui.png',
  },
  {
    id: 2,
    title: 'Coding',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704888567/curriculum/coding_wc7bm4.png',
  },
  {
    id: 3,
    title: 'Multimedia Design',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704888567/curriculum/multimedia_Design_qlyqhe.png',
  },
  {
    id: 4,
    title: '3D Design & 3D Printing',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1704888566/curriculum/Design_3D_Printing_xisum9.png',
  },
  {
    id: 5,
    title: 'Robotics & IOT',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1708981824/curriculum/fe8e7b14-691e-42c0-a7dc-7dccc284087f.png',
  },
  {
    id: 6,
    title: 'Specialized Courses',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1708981781/curriculum/9bffabd8-fa70-4040-851a-522ff054d82b.png',
  },
  {
    id: 7,
    title: 'Artificial Intelligence',
    image:
      'https://res.cloudinary.com/depkwgbwl/image/upload/v1708981802/curriculum/99bc9587-34a1-4cce-9690-3119f12ede10.png',
  },
];

const Curriculum = () => {
  return (
    <section className='relative overflow-hidden bg-white pt-32' id='courses'>
      <div className='absolute inset-0 top-3 flex h-6 items-center justify-center md:top-[18px]'>
        <svg
          width='122'
          height='60'
          viewBox='0 0 122 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            id='Polygon 1'
            d='M61 59.9221L0.37822 -0.0778809L121.622 -0.0778809L61 59.9221Z'
            fill='#BC00DD'
          />
        </svg>
      </div>
      <div
        style={{ backgroundImage: "url('/assets/star.png')" }}
        className='absolute -right-10 -top-20 h-1/3 w-40 bg-no-repeat object-contain max-sm:scale-50 md:right-0 lg:right-0 lg:top-0 lg:h-full lg:w-40'
      />

      <div className='absolute inset-0 -left-16 top-20 mx-auto h-6 w-6 md:top-40'>
        <svg
          width='100'
          height='100'
          viewBox='0 0 100 100'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='50' cy='50' r='50' fill='#FFB600' />
          <path
            d='M46.2688 24.8926C47.8424 22.4366 51.431 22.4366 53.0047 24.8926L53.3377 25.4124C54.4524 27.152 56.7084 27.7565 58.5434 26.8073L59.0918 26.5236C61.6827 25.1835 64.7905 26.9778 64.9253 29.8916L64.9538 30.5083C65.0493 32.5721 66.7008 34.2236 68.7646 34.3191L69.3814 34.3477C72.2952 34.4825 74.0895 37.5903 72.7493 40.1811L72.4657 40.7295C71.5165 42.5646 72.121 44.8206 73.8605 45.9352L74.3804 46.2683C76.8364 47.8419 76.8364 51.4305 74.3804 53.0042L73.8605 53.3373C72.121 54.4519 71.5165 56.7079 72.4657 58.543L72.7493 59.0913C74.0895 61.6822 72.2952 64.79 69.3814 64.9248L68.7647 64.9533C66.7008 65.0488 65.0493 66.7003 64.9538 68.7642L64.9253 69.3809C64.7905 72.2947 61.6827 74.089 59.0918 72.7488L58.5434 72.4652C56.7084 71.516 54.4524 72.1205 53.3377 73.8601L53.0047 74.3799C51.431 76.8359 47.8424 76.8359 46.2688 74.3799L45.9357 73.8601C44.8211 72.1205 42.5651 71.516 40.73 72.4652L40.1816 72.7488C37.5908 74.089 34.483 72.2947 34.3482 69.3809L34.3196 68.7642C34.2241 66.7003 32.5726 65.0488 30.5088 64.9533L29.8921 64.9248C26.9783 64.79 25.184 61.6822 26.5241 59.0913L26.8078 58.543C27.757 56.7079 27.1525 54.4519 25.4129 53.3373L24.8931 53.0042C22.437 51.4305 22.437 47.8419 24.8931 46.2683L25.4129 45.9352C27.1525 44.8206 27.757 42.5646 26.8078 40.7295L26.5241 40.1811C25.184 37.5903 26.9782 34.4825 29.8921 34.3477L30.5088 34.3191C32.5726 34.2236 34.2241 32.5721 34.3196 30.5083L34.3482 29.8916C34.483 26.9778 37.5908 25.1835 40.1816 26.5236L40.73 26.8073C42.5651 27.7565 44.8211 27.152 45.9357 25.4124L46.2688 24.8926Z'
            fill='white'
          />
        </svg>
      </div>

      <h2 className='mb-4 mt-20 text-center text-3xl font-semibold uppercase md:mt-40 lg:text-7xl lg:font-normal'>
        Curriculum
      </h2>

      <div
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/depkwgbwl/image/upload/v1705761453/curriculum_bg_oumlkz.png')",
          height: '100%',

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className='relative'
      >
        <div className='mx-auto flex max-h-[639px] max-w-7xl flex-col bg-[#573b5b] bg-opacity-70 pt-8'>
          <p className='pl-4 text-4xl font-semibold text-white'>Courses</p>

          <Swiper
            slidesPerGroup={1}
            slidesPerView={'auto'}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            loop={true}
            modules={[Navigation, Autoplay, Pagination, A11y]}
            className='relative mx-auto flex w-full py-24 max-sm:px-9 lg:max-w-7xl'
          >
            {programData.map((program) => (
              <SwiperSlide key={program.id}>
                <article className='relative flex flex-1 justify-center rounded-br-[30px] rounded-tl-3xl bg-white transition-all duration-300 hover:scale-105'>
                  <img
                    src={program.image}
                    alt=''
                    className='h-72 w-full'
                    loading='lazy'
                  />

                  <button className='absolute bottom-3 min-w-40 rounded-lg bg-white px-3 py-1 text-lg font-semibold'>
                    {program.title}
                  </button>
                </article>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Curriculum;
